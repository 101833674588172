import * as Sentry from '@sentry/browser'
import { showAgreements } from './agreements'
import { processResponse } from '../../behaviors/remote/process_response'
import $ from 'jquery'
import { jQueryBeforeSendAddCsrfToken } from '~/common/helpers/jquery_ajax'
import { attachDynamicEvent } from '~/common/helpers/dom'

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  ignoreErrors: ['Failed to fetch', 'Load failed'],
})

export function updateCart() {
  return doUpdate(getBaseCartPath() + '/update_totals')
}

// This one will also update the price list.
export async function updateCartAndPrices() {
  showLoading()
  await doUpdate(getBaseCartPath() + '/update_totals_and_prices')
  hideLoading()
}

// This one will also update the donation fields (and in the future anything else that needs to be updated as a result of price being changed)
// We pass the current_price_id so we can compare the old and newly selected price to check if payment methods would change
export async function updateCartOnPriceChange(options = {}) {
  showLoading()
  let path = getBaseCartPath() + '/update_on_price_change'
  if (options.updatePaymentMethods === false) path += '?skip_payment_methods=1'
  await doUpdate(path)
  hideLoading()
}

export async function updateOnVolumeDiscountSelected(options = {}) {
  showLoading()
  let path = getBaseCartPath() + '/update_on_volume_discount_selected'
  if (options.productId && options.priceId) {
    path += '?volume_discount_product_id=' + options.productId
    path += '&volume_discount_price_id=' + options.priceId
  }
  await doUpdate(path)
  hideLoading()
}

export async function updateCartOnAddressChange() {
  return await doUpdate(getBaseCartPath() + '/update_on_address_change')
}

export async function updateCartOnPostalCodeChange() {
  return await doUpdate(getBaseCartPath() + '/update_on_postal_code_change')
}

export async function updateCartOnEmailChange() {
  // TODO - we need some smart replacement logic here
  showLoading()
  await doUpdate(getBaseCartPath() + '/update_on_email_change')
  hideLoading()
}

export async function updateQuantity() {
  showLoading()
  await doUpdate(getBaseCartPath() + '/update_quantity')
  hideLoading()
}

export async function removeItem(id) {
  showLoading()
  await doUpdate(
    getBaseCartPath() + '/update_quantity' + `?remove_product_id=${id}`
  )
  hideLoading()
}

// options can be { orderBumpId: 123 } in case we're adding an order bump
// this allow us to know which order bump is being added and replace any other order bump with the same product
export async function updateOrderBumps(options) {
  const addedOrderBumpId = options?.orderBumpId
  let path = getBaseCartPath() + '/update_order_bumps'
  showLoading()
  if (addedOrderBumpId) path += `?order_bump_id=${addedOrderBumpId}`
  await doUpdate(path)
  hideLoading()
}

// forAgreements true allows us to update strictly the entrants without updating the rest of the cart (e.g. without updating payment form component)
export async function updateEntrants(forAgreements = false) {
  showLoading()
  await doUpdate(
    getBaseCartPath() +
      `/update_entrants${forAgreements ? '?for_agreements=1' : ''}`
  )
  hideLoading()
}

export async function updateAddressInfo() {
  showLoading()
  await doUpdate(getBaseCartPath() + '/update_address_info')
  hideLoading()
}

async function doUpdate(path) {
  let form = document.querySelector('.cart-checkout-details')
  const formData = new FormData(form)

  // In the new cart checkout we have quantity selector for each item, living on a separate form
  // Here, we try to find them, and append their values to the formData
  // which is what we are going to send in the PATCH request
  const cartItemForms = document.querySelectorAll('.js-cart-summary-item-form')
  cartItemForms.forEach((form) => {
    const qtySelector = form.querySelector('.js-cart-item-quantity-select')
    if (qtySelector) {
      formData.append(qtySelector.name, qtySelector.value)
    }
  })

  if (form.getAttribute('data-loading-cart-update')) {
    form.setAttribute('data-pending-cart-update', '1')
    return
  }
  form.setAttribute('data-loading-cart-update', '1')

  return fetch(path, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams(formData),
  })
    .then((response) => response.json())
    .then((json) => {
      processResponse(json)
      form.removeAttribute('data-loading-cart-update')
      incrementCartUpdateCounter()
      if (form.getAttribute('data-pending-cart-update')) {
        form.removeAttribute('data-pending-cart-update')
        doUpdate(path)
      }
    })
}

export function incrementCartUpdateCounter() {
  let form = document.querySelector('.cart-checkout-details')
  // cart-update-counter is used for tests
  form.setAttribute(
    'data-cart-update-counter',
    parseInt(form.getAttribute('data-cart-update-counter') || 0, 10) + 1
  )
}

export async function proceedWithPayment(form) {
  if (!form.reportValidity()) {
    // Ensure that we're going to be showing *something* if this fails. We shouldn't have hidden fields with HTML
    // validation, but we could...
    let invalidFields = Array.from(form.querySelectorAll(':invalid'))
    let visibleInvalidFields = invalidFields.filter((el) =>
      $(el).is(':visible')
    )
    if (visibleInvalidFields.length == 0) {
      console.log('Found no visible invalid fields on order form submit.')
      // Try to capture Sentry message reporting this error still happens
      Sentry.captureMessage(
        'Found no visible invalid fields on order form submit. Found invalid invisible fields: ' +
          invalidFields.map((el) => el.name)
      )
    }
    return false
  }

  // If the entrants need to sign a pre-purchase agreement, update the entrants before showing the agreements component.
  // This way we ensure the agreements always have the latest entrants data.
  const isOneStepForm = document.querySelector('.cart-checkout-details')
  if (isOneStepForm) {
    const hasAgreements =
      form.querySelector('[data-cart--agreements-target="agreement"]') ||
      document.querySelector(
        '.tw-modal-containers #cart-agreements [data-cart--agreements-target="agreement"]'
      )
    if (hasAgreements) {
      return await updateEntrants(true).then(async () => {
        form.removeAttribute('data-update-entrants')
        return await showAgreements()
      })
    }
  }

  return await showAgreements()
}

const loadingClasses = [
  'cart-checkout-page--loading',
  'opacity-50',
  'cursor-wait',
  'pointer-events-none-on-children',
]

export function showLoading() {
  mainContainer().classList.add(...loadingClasses)
}

export function hideLoading() {
  mainContainer().classList.remove(...loadingClasses)
  const submitButton = document.querySelector(
    '.cart-checkout-payment button[type="submit"]'
  )
  // Can't really import Rails from ujs because this file is imported from contexts where it causes all sort of weird issues
  if (submitButton && window.Rails && window.Rails.enableElement) {
    window.Rails.enableElement(submitButton)
  } else if (submitButton) {
    submitButton.disabled = false
    const reenableWith = submitButton.dataset.reenableWith
    const disableWith = submitButton.dataset.disableWith

    if (reenableWith && submitButton.innerText == disableWith) {
      submitButton.innerHTML = reenableWith || 'Submit'
    }
  }
}

export function mainContainer() {
  return document.querySelector('.cart-checkout-page, .content-container')
}

export function getBaseCartPath() {
  return document.querySelector('[data-base-cart-path]').dataset.baseCartPath
}

export function updateCartCount(count) {
  let containers = document.querySelectorAll('.shopping-cart-icon-container')
  containers.forEach((container) => {
    container
      .querySelectorAll('.shopping-cart-button__item-count span')
      .forEach((el) => (el.innerText = count))
    if (count == 0) {
      container.classList.add('shopping-cart-icon-container--empty')
    } else {
      container.classList.remove('shopping-cart-icon-container--empty')
    }
  })
}

export function onPaymentMethodChange(listener) {
  attachDynamicEvent(
    '.cart-checkout-payment .payment-method-or-processor-option input[type=radio]',
    'change',
    listener
  )
}

export function updateCartItemQuantityNonDebounced(item, quantity) {
  const $item = $(item)
  $item.addClass('cart__item--loading')
  $item.closest('.cart').addClass('cart--loading')
  return $.ajax({
    url: $item.data('update-url') + '?old_checkout=true',
    type: 'PATCH',
    data: { cart_item: { quantity } },
    beforeSend: jQueryBeforeSendAddCsrfToken,
    complete: (response) => {
      $item.removeClass('cart__item--loading')
      $item.closest('.cart').removeClass('cart--loading')
      const data = response.responseJSON
      processResponse(data)
      return updateCartCount(data.cartCount)
    },
  })
}
