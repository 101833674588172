/*
  The same file, in coffeescript version, is in app/assets/javascripts/global/email_autocorrect.coffee for use by
  simplero.js
 */

import $ from 'jquery'
import '../../../../../vendor/assets/javascripts/js-levenshtein'
import { attachDynamicEvent } from '~/common/helpers/dom'

const DISTANCE_THRESHOLD = 2
const COMMON_DOMAIN_NAMES = [
  'gmail.com',
  'hotmail.com',
  'yahoo.com',
  'aol.com',
  'comcast.net',
  'live.dk',
  'icloud.com',
  'outlook.com',
  'msn.com',
  'cox.net',
  'yahoo.dk',
  'live.com',
  'sbcglobal.net',
  'me.com',
  'live.se',
  'att.net',
  'telia.com',
  'hotmail.co.uk',
  'online.no',
  'verizon.net',
  'hotmail.se',
  'bellsouth.net',
  'mail.dk',
  'charter.net',
  'yahoo.co.uk',
  'ymail.com',
  'bigpond.com',
  'hotmail.dk',
  'outlook.dk',
  'mail.ru',
  'windstream.net',
  'yahoo.se',
  'mac.com',
  'yahoo.no',
  'live.no',
  'yahoo.com.au',
  'spray.se',
  'mail.com',
  'rocketmail.com',
  'atlanticbb.net',
  'hotmail.no',
  'stofanet.dk',
  'googlemail.com',
  'qq.com',
  'optonline.net',
  'live.com.au',
  'earthlink.net',
  'live.co.uk',
  'jubii.dk',
  'suddenlink.net',
  'yahoo.ca',
  'btinternet.com',
  'privat.dk',
  'hotmail.fr',
  'optusnet.com.au',
  'comhem.se',
  'bredband.net',
  'webspeed.dk',
  'juno.com',
  'sol.dk',
  'yahoo.fr',
  'godmail.dk',
  'shaw.ca',
  'tele2.se',
  'mail.tele.dk',
  'yahoo.co.in',
  'frontier.com',
  'youmail.dk',
  'lyse.net',
  'bigpond.net.au',
  'rediffmail.com',
  'yahoo.es',
  'aim.com',
  'roadrunner.com',
  'yandex.ru',
]

// If a domain is in WHITE_LISTED_DOMAINS, it is not auto corrected
// If a domain is close to a domain in WHITE_LISTED_DOMAINS and not in COMMON_DOMAIN_NAMES, it is not auto corrected either
// When adding domains that are false positive candidates for autocorrection but not used by many people, add them to this list so auto correction is
// still fast
const WHITE_LISTED_DOMAINS = COMMON_DOMAIN_NAMES.concat([
  'email.dk',
  'os.dk',
  'live.ca',
  'twc.com',
  'sti.net',
  'homemail.com',
  'gmx.com',
  'mailme.com',
  'yahoo.com.sg',
  'yahoo.de',
  'getmail.no',
  'gmx.net',
  'mail.fo',
  'mailme.dk',
  'live.nl',
])
// Example usage:
//
// Initialize
//
// autoCorrectOnSubmit: will add an event handler to automatically correct on form submit
// correctionTextsDataContainerSelector: will use the text from data attributes of this element for displaying messages like We changed x to y. Change back.
//
// $(document).initializeEmailAutoCorrector({
//   inputSelector: '.signup-form__field input[type=email]',
//   correctionTextsDataContainerSelector: '.signup-form'
//   autoCorrectOnSubmit: true,
// })
//
//
// Check
//
// $("input[type='email']").autoCorrectEmail()
//
// Will return true if an autocorrect was performed on *any* of the elements supplied just now or within the last 250 millmilliseconds
// Will return false otherwise

const extendWithAutoCorrector = function ($) {
  if (!$ || typeof $ !== 'function') {
    return
  }
  if ($.fn.initializeEmailAutoCorrector) {
    return
  }

  const usingJquery = !!$().jquery

  const removeNoticeWithFadeout = function (noticeElem) {
    const wrapper = noticeElem.parent()
    const marginBottom = wrapper.parent().hasClass('simplero-signup-form')
      ? '0.5em'
      : '0'
    noticeElem.parent().css('margin-bottom', marginBottom)
    // Cant use fadeOut cause zepto doesn't support it
    noticeElem.css('opacity', 0)
    return setTimeout(() => noticeElem.remove(), 300)
  }

  $.fn.autoCorrectEmail = function (correctionTextsDataContainerSelector) {
    let autoCorrected = false
    // Note: return true inside a jquery.each means continue; return false means break
    this.each(function (index, inputElement) {
      const input = $(inputElement)
      if (input.data('autocorrected-on')) {
        return true
      }

      const email = input.val()
      const [address, domain] = Array.from(email.toLowerCase().split('@'))
      if (!address || !domain) {
        return true
      }
      if (Array.from(WHITE_LISTED_DOMAINS).includes(domain)) {
        return true
      }

      let bestDomainMatch = null
      let bestDistance = 999

      COMMON_DOMAIN_NAMES.map(function (commonDomainName) {
        const distance = window.jsLevenshtein(domain, commonDomainName)
        if (distance < bestDistance) {
          bestDomainMatch = commonDomainName
          return (bestDistance = distance)
        }
      })

      if (
        bestDomainMatch &&
        bestDistance <= DISTANCE_THRESHOLD &&
        bestDistance !== 0
      ) {
        let getTextForEmailCorrection
        if (window.I18n && window.I18n.t) {
          getTextForEmailCorrection = window.I18n.t
        } else if (correctionTextsDataContainerSelector) {
          getTextForEmailCorrection = function (key, options) {
            const dataContainer = $(correctionTextsDataContainerSelector)
            if (key === 'site.general.email_correction_message') {
              return dataContainer
                .data('email-correction-message')
                .replace('%{original_domain}', options.original_domain)
                .replace('%{corrected_domain}', options.corrected_domain)
            } else if (key === 'site.general.email_correction_revert_text') {
              return dataContainer.data('email-correction-revert-text')
            }
          }
        } else {
          throw new Error(
            'Cant find I18n and no fallback function to get text specified'
          )
        }

        const parent = input.parent()
        parent.addClass('email-input-notice-container')
        input.data('autocorrected-on', new Date().getTime())
        input.val(address + '@' + bestDomainMatch)

        const revertCorrectionText = getTextForEmailCorrection(
          'site.general.email_correction_revert_text'
        )
        const correctionText = getTextForEmailCorrection(
          'site.general.email_correction_message',
          { original_domain: domain, corrected_domain: bestDomainMatch }
        )

        const revertCorrectionLink = $('<a></a>')
          .attr('href', '#')
          .attr('tabindex', '-1')
          .append(revertCorrectionText)
        revertCorrectionLink.on('click', function (e) {
          e.preventDefault()
          input.val(email)
          removeNoticeWithFadeout(correctionNotice)
          // If anything inherits the value, change that back too.
          return input.trigger('simplero:inherit')
        })

        var correctionNotice = $('<div></div>')
          .addClass('email-autocorrect-notice')
          .append(correctionText + ' ')
          .append(revertCorrectionLink)

        // Svg because we can't rely on font awesome to always be present
        const closeIcon = $(
          '<svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" viewBox="0 0 24 24" stroke="currentColor"><path d="M6 18L18 6M6 6l12 12"></path></svg>'
        )
        closeIcon.on('click', (e) => removeNoticeWithFadeout(correctionNotice))
        correctionNotice.append(closeIcon)

        setTimeout(() => removeNoticeWithFadeout(correctionNotice), 10000)

        input.after(correctionNotice)
        parent.css('margin-bottom', correctionNotice[0].scrollHeight + 'px')
        correctionNotice.css('opacity', 1)
        input.focus()
        if (input.scrollIntoView) {
          input.scrollIntoView()
        }
        autoCorrected = true
        return false
      }
    })

    if (autoCorrected) {
      return true
    }

    // We check if there are any inputs that were recently auto corrected < 250 milliseconds but not in this call
    this.each(function (index, inputElement) {
      const input = $(inputElement)
      const recentlyAutoCorrected =
        new Date().getTime() - input.data('autocorrected-on') < 250
      if (recentlyAutoCorrected) {
        autoCorrected = true
        return false
      }
    })

    return autoCorrected
  }

  return ($.fn.initializeEmailAutoCorrector = function (...args) {
    let obj = args[0],
      { inputSelector } = obj,
      val = obj.autoCorrectOnBlur,
      autoCorrectOnBlur = val != null ? val : true,
      val1 = obj.removeSuggestionOnInput,
      removeSuggestionOnInput = val1 != null ? val1 : true,
      val2 = obj.autoCorrectOnSubmit,
      autoCorrectOnSubmit = val2 != null ? val2 : false,
      val3 = obj.correctionTextsDataContainerSelector,
      correctionTextsDataContainerSelector = val3 != null ? val3 : false,
      val4 = obj.formSelector,
      formSelector = val4 != null ? val4 : 'form'
    if (!inputSelector) {
      throw new Error('Input selector is required')
    }

    const inputElm = $(inputSelector)
    if (inputElm.parent().hasClass('simplero-signup-form')) {
      // Special case for embedded landing page forms
      // where the input parent contains all the input fields
      const wrapper = $('<div></div>')
        .css('width', '100%')
        .css('margin-bottom', '0.5em')
        .addClass('simplero-field simplero-email-wrapper')
      inputElm.css('margin-bottom', 0)
      inputElm.wrap(wrapper)
    }

    if (removeSuggestionOnInput) {
      $(document).on('input', inputSelector, function (e) {
        const input = $(e.target)
        return removeNoticeWithFadeout(
          input.parent().find('.email-autocorrect-notice')
        )
      })
    }

    if (autoCorrectOnBlur) {
      $(document).on('blur', inputSelector, function (e) {
        const input = $(e.target)
        return input.autoCorrectEmail(correctionTextsDataContainerSelector)
      })
    }

    // This is by default false because in most cases you should already have a form submit listener and should handle this case there
    if (autoCorrectOnSubmit) {
      attachDynamicEvent(
        formSelector,
        'submit',
        (e) => {
          inputSelector = "input[type='email']"
          if (usingJquery) {
            inputSelector += ':visible'
          }
          if (
            $(e.target)
              .find(inputSelector)
              .autoCorrectEmail(correctionTextsDataContainerSelector)
          ) {
            e.stopImmediatePropagation()
            e.preventDefault()
            return false
          }
        },
        { capture: true }
      )
    }

    if ($('#email-autocorrect-style').length === 0) {
      // Can't use scss because scss in views/landing_pages doesnt support import
      const cssString = `\
.email-input-notice-container {
  position: relative;
  transition: 0.3s;
}
.email-input-notice-container .email-autocorrect-notice {
  font-size: 0.85em;
  display: inline-block;
  background: #faf5dc !important;
  color: #706734 !important;
  padding: 6px 24px 6px 12px !important;
  margin: 0px !important;
  transition: opacity 0.3s;
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}
.email-input-notice-container .email-autocorrect-notice a {
  display: inline-block !important;
  color: #5db0ea;
  text-decoration: underline;
}
.email-input-notice-container .email-autocorrect-notice svg {
  cursor: pointer;
  height: 12px;
  width: 12px;
  position: absolute;
  right: 12px;
  bottom: 100%;
  top: 50%;
  transform: translateY(-50%);
  color: #6c7478;
  opacity: 0.75;
  transition: opacity 0.3s;
}
.email-input-notice-container .email-autocorrect-notice svg:hover {
  color: #4c5154;
  opacity: 1;
}\
`
      const style = document.createElement('style')
      style.id = 'email-autocorrect-style'
      style.textContent = cssString
      document.head.append(style)
    }

    // Just something for tests to key in on
    inputElm.addClass('email-autocorrect-initialized')
  })
}

extendWithAutoCorrector($)
